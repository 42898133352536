import React from 'react'

function TurnCampaignInsightsInToQualifiedLeadsStraumannCasestudy() {
    return (
        <div style={{ height: "100vh" }}>
            <object data="../../Turn-campaign-insights-in-to-qualified-leads-Straumann-Casestudy.pdf#toolbar=0" type="application/pdf" width="100%" height="100%">
                <a href="../../Turn-campaign-insights-in-to-qualified-leads-Straumann-Casestudy.pdf"></a>
            </object>
        </div>
    )
}

export default TurnCampaignInsightsInToQualifiedLeadsStraumannCasestudy